<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="名称" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column label="图标" width="80">
        <template v-slot="s">
          <img :src="s.row.pic | tomedia" style="height: 2rem;width: 2rem;">
        </template>
      </el-table-column>
      <el-table-column label="升级条件" >
        <template v-slot="s">
          直推代理 + 邀请关系链下代理 + 直推老板数量总和 至少 {{s.row.auto_up_limit}} 人
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="200" show-overflow-tooltip>
        <template v-slot:header>
          <el-button @click="create" type="primary">添加等级</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="editDose(s.row)" type="primary">编辑</el-button>
          <el-button @click="del(s.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="编辑等级" :visible.sync="editBox" @close="editBoxClose" width="30rem">
      <div>
        <el-form label-width="70px">
          <el-form-item label="等级名称">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="等级图标">
            <y_upload_img v-model="editForm.pic"></y_upload_img>
          </el-form-item>
          <el-form-item label="升级条件">
            直推代理 + 邀请关系链下代理 + 直推老板数量总和 至少
            <el-input-number v-model="editForm.auto_up_limit"></el-input-number>
            人
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  components:{
    y_upload_img
  },
  name: "list",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{
        title:"",
        pic:"",
        auto_up_limit:0,
      },
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    del({id}){
      this.$u.api.shop.plugin.decorateLevel.levelDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    editDose(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editBoxClose(){
      this.editForm = {
        title:"",
        pic:"",
        auto_up_limit:0,
      }
    },
    create(){
      this.editBox=true
    },
    editSubmit(){
      this.$u.api.shop.plugin.decorateLevel.levelEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.decorateLevel.levelAll().then(res=>{
        this.list = res;
      })
    },
  },
}
</script>

<style scoped>

</style>